code-input {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    > span:not(:last-child) {
        margin-right: 4px !important;
    }
    span > input {
        height:50px !important;
        background-color: $gray-light !important;
    }
  }